/* eslint-disable no-param-reassign */

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DropdownView, getOptimalPosition } from 'ckeditor5';
import type { ClassicEditor, EventInfo, ToolbarView, View, ViewCollection } from 'ckeditor5';

export const disableHTMLPasteOnMobile = ({
  editor,
  event,
  data,
}: {
  editor: ClassicEditor;
  event: EventInfo<string, unknown>;
  data: any;
}) => {
  const htmlContent = data.dataTransfer.getData('text/html');

  // 사진, 표 복붙은 OK
  const containsTableOrFigure =
    /<(table|fig(?:ure|caption)|thead|tbody|tfoot|tr|td|th|img)(\s|>)/i.test(htmlContent);

  if (containsTableOrFigure) return;

  const plainText = data.dataTransfer.getData('text/plain');
  event.stop();

  editor.model.change((writer) => {
    const insertPosition = editor.model.document.selection.getFirstPosition();
    if (insertPosition) writer.insertText(plainText, insertPosition);
  });
};

/**
 * 공식문서 참고하고 약간 수정: https://ckeditor.com/docs/ckeditor5/latest/examples/framework/bottom-toolbar-editor.html
 * Force all toolbar dropdown panels to use northern positions rather than southern (editor default).
 * This will position them correctly relative to the toolbar at the bottom of the editing root.
 *
 * @private
 * @param {module:core/editor/editor~Editor} editor
 * @param {module:ui/toolbar/toolbarview~ToolbarView} toolbarView
 */
export const overrideDropdownPositionsToNorth = ({
  editor,
  toolbarView,
}: {
  editor: ClassicEditor;
  toolbarView: ToolbarView;
}) => {
  const {
    south,
    north,
    southEast,
    southWest,
    northEast,
    northWest,
    southMiddleEast,
    southMiddleWest,
    northMiddleEast,
    northMiddleWest,
  } = DropdownView.defaultPanelPositions;

  let panelPositions;

  if (editor.locale.uiLanguageDirection !== 'rtl') {
    panelPositions = [
      northEast,
      northWest,
      northMiddleEast,
      northMiddleWest,
      north,
      southEast,
      southWest,
      southMiddleEast,
      southMiddleWest,
      south,
    ];
  } else {
    panelPositions = [
      northWest,
      northEast,
      northMiddleWest,
      northMiddleEast,
      north,
      southWest,
      southEast,
      southMiddleWest,
      southMiddleEast,
      south,
    ];
  }

  toolbarView.items.forEach((item) => {
    if (!(item instanceof DropdownView)) {
      return;
    }

    item.on('change:isOpen', () => {
      if (!item.isOpen) {
        return;
      }

      item.panelView.position = getOptimalPosition({
        element: item.panelView.element!,
        target: item.buttonView.element!,
        fitInViewport: true,
        positions: panelPositions,
      })!.name as any;
    });
  });
};

// overrideDropdownPositionsToNorth 함수 참고하여 Grouping 된 툴바 드롭다운 (점 3개) 위치 변경
export const overrideGroupDropDownPositionsToNorth = ({
  editor,
  viewCollections,
}: {
  editor: ClassicEditor;
  viewCollections: ViewCollection<View<HTMLElement>>;
}) => {
  const {
    south,
    north,
    southEast,
    southWest,
    northEast,
    northWest,
    southMiddleEast,
    southMiddleWest,
    northMiddleEast,
    northMiddleWest,
  } = DropdownView.defaultPanelPositions;

  let panelPositions;

  if (editor.locale.uiLanguageDirection !== 'rtl') {
    panelPositions = [
      northEast,
      northWest,
      northMiddleEast,
      northMiddleWest,
      north,
      southEast,
      southWest,
      southMiddleEast,
      southMiddleWest,
      south,
    ];
  } else {
    panelPositions = [
      northWest,
      northEast,
      northMiddleWest,
      northMiddleEast,
      north,
      southWest,
      southEast,
      southMiddleWest,
      southMiddleEast,
      south,
    ];
  }

  viewCollections.forEach((item) => {
    if (!(item instanceof DropdownView)) {
      return;
    }

    item.on('change:isOpen', () => {
      if (!item.isOpen) {
        return;
      }

      item.panelView.position = getOptimalPosition({
        element: item.panelView.element!,
        target: item.buttonView.element!,
        fitInViewport: true,
        positions: panelPositions,
      })!.name as any;
    });
  });
};
