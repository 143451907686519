import * as Sentry from '@sentry/react';
import type { BrowserOptions } from '@sentry/react';

export { captureMessage } from '@sentry/react';
export { reactRouterV6BrowserTracingIntegration } from '@sentry/react';

// ------------------------ api handler -------------------------------

const SENTRY_CONTEXT_AUTH_API_ERROR_KEY = 'SENTRY_CONTEXT_AUTH_API_ERROR_KEY';
export const handleSentry401AuthError = ({
  accessToken,
  refreshToken,
  message = '로그인 유저의 401 error가 발생했어요.',
}: {
  message?: string;
  accessToken: string | null;
  refreshToken: string | null;
}) => {
  Sentry.setContext(SENTRY_CONTEXT_AUTH_API_ERROR_KEY, {
    accessToken,
    refreshToken,
  });
  Sentry.captureMessage(message);
  Sentry.setContext(SENTRY_CONTEXT_AUTH_API_ERROR_KEY, null);
};

// ------------------------ api handler -------------------------------
const PREVENT_SENTRY_URL_LIST = ['/users/validate-user', '/users/duplicate-email', '/users/login'];
export const setSentryContextApi = ({ error }: { error: any }) => {
  const errorEndpoint = error.config?.url ?? '알 수 없음';
  if (PREVENT_SENTRY_URL_LIST.includes(errorEndpoint)) return;
  setSentryContextApiReq({ config: error?.config });
  setSentryContextApiRes({ response: error?.response });
  Sentry.setExtra('error', error);
  Sentry.captureException(error);
  Sentry.captureMessage(`${errorEndpoint} 에러가 발생하였습니다!`);
  setSentryContextApiClear();
};

const SENTRY_CONTEXT_API_REQ_KEY = 'API Request Detail';
const SENTRY_CONTEXT_API_RES_KEY = 'API Response Detail';
const setSentryContextApiReq = ({
  config,
}: {
  config: {
    method: any;
    url: any;
    params: any;
    data: any;
    headers: any;
  };
}) => {
  Sentry.setContext(SENTRY_CONTEXT_API_REQ_KEY, {
    method: config?.method,
    url: config?.url,
    params: config?.params,
    data: config?.data,
    headers: config?.headers,
  });
};

const setSentryContextApiRes = ({
  response,
}: {
  response: {
    data: any;
    status: any;
  };
}) => {
  Sentry.setContext(SENTRY_CONTEXT_API_RES_KEY, {
    data: response?.data,
    status: response?.status,
  });
};

const setSentryContextApiClear = () => {
  Sentry.setContext(SENTRY_CONTEXT_API_REQ_KEY, null);
  Sentry.setContext(SENTRY_CONTEXT_API_RES_KEY, null);
};

// ------------------------ user handler -------------------------------

type UserInfoType = 'userLevel' | 'name' | 'email' | 'admissionCount' | 'admissionsAvailableCounts';
const SENTRY_CONTEXT_USER_INFO_KEY = 'userInfo';

export const setSentryContextUserInfo = ({ key, value }: { key: UserInfoType; value: any }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   @ts-ignore
  const userInfoValue = Sentry.getCurrentScope()._contexts?.[SENTRY_CONTEXT_USER_INFO_KEY] ?? null;
  const newValue =
    typeof userInfoValue === 'object'
      ? {
          ...userInfoValue,
          [key]: value,
        }
      : {
          [key]: value,
        };
  Sentry.setContext(SENTRY_CONTEXT_USER_INFO_KEY, newValue);
};

/**
 * @see https://docs.sentry.io/platforms/javascript/enriching-events/context/
 * Sentry 유저 데이터 적재를 위해 임시로 사용
 * @param user - User
 */
export const setSentryContextUser = (user: Sentry.User) => {
  Sentry.setUser(user);
};

export const setSentryContextUserInfoClear = () => {
  Sentry.setContext(SENTRY_CONTEXT_USER_INFO_KEY, null);
  Sentry.setUser(null);
};

export const init = (options: Omit<BrowserOptions, 'dsn' | 'normalizeDepth'>) => {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_URL,
    normalizeDepth: 6,
    ...options,
  });
};
