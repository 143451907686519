/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Alignment,
  AutoImage,
  AutoLink,
  Autoformat,
  Bold,
  ClassicEditor as ClassicEditorBase,
  Clipboard,
  Essentials,
  Font,
  GeneralHtmlSupport,
  Heading,
  HtmlEmbed,
  Image,
  ImageBlock,
  ImageResize,
  ImageResizeEditing,
  ImageResizeHandles,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  Strikethrough,
  Table,
  TableToolbar,
  Underline,
} from 'ckeditor5';
// @ts-ignore
import translations from 'ckeditor5/translations/ko.js';
import { isBrowser } from 'react-device-detect';

const SHARE_TOOLBAR_ITMES = ['undo', 'redo', '|', 'imageUpload'];

const PC_ONLY_TOOLBAR_ITEMS = [
  'findAndReplace',
  'selectAll',
  'textPartLanguage',
  '|',
  'heading',
  '|',
  'fontSize',
  'fontFamily',
  'fontColor',
  'fontBackgroundColor',
  '|',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'subscript',
  'superscript',
  'code',
  'removeFormat',
  '|',
  'specialCharacters',
  'horizontalLine',
  'link',
  'insertTable',
  'highlight',
  'blockQuote',
  'codeBlock',
  '|',
  'alignment',
  '|',
  'bulletedList',
  'numberedList',
  'indent',
  'outdent',
  '|',
];

export default class CustomCKEditor extends ClassicEditorBase {}

CustomCKEditor.builtinPlugins = [
  Essentials,
  Font,
  Bold,
  Underline,
  Italic,
  Strikethrough,
  Alignment,
  List,
  Indent,
  IndentBlock,
  Image,
  ImageStyle,
  ImageToolbar,
  Link,
  Table,
  TableToolbar,
  Heading,
  ImageUpload,
  ImageBlock,
  ImageResize,
  ImageResizeEditing,
  ImageResizeHandles,
  GeneralHtmlSupport,
  HtmlEmbed,
  AutoLink,
  AutoImage,
  Autoformat,
  Clipboard,
];

CustomCKEditor.defaultConfig = {
  // @ts-ignore
  fontFamily: {
    options: [
      'Noto Sans KR',
      'Noto Sans JP',
      'Noto Sans',
      'Helvetica Neue',
      'Arial',
      'Hiragino Kaku Gothic ProN',
      'Hiragino Sans',
      'Meiryo',
    ],
  },

  fontSize: {
    options: Array.from({ length: 8 }, (_, index) => index * 2 + 10),
    supportAllValues: true,
  },

  alignment: {
    options: ['justify', 'center', 'right'],
  },

  toolbar: {
    shouldNotGroupWhenFull: false,
    items: [...SHARE_TOOLBAR_ITMES, ...(isBrowser ? PC_ONLY_TOOLBAR_ITEMS : [])],
  },

  link: {
    addTargetToExternalLinks: true,
    // You can use `s?` suffix like below to allow both `http` and `https` protocols at the same time.
    allowedProtocols: ['https?', 'tel', 'sms', 'sftp', 'smb', 'slack'],
  },
  image: {
    resizeOptions: [
      {
        name: 'resizeImage:original',
        label: 'Original',
        value: null,
      },
      {
        name: 'resizeImage:25',
        label: '25%',
        value: '25',
      },
      {
        name: 'resizeImage:50',
        label: '50%',
        value: '50',
      },
      {
        name: 'resizeImage:75',
        label: '75%',
        value: '75',
      },
      {
        name: 'resizeImage:100',
        label: '100%',
        value: '100',
      },
    ],
    toolbar: [
      'toggleImageCaption',
      'imageTextAlternative',
      '|',
      'imageStyle:inline',
      'imageStyle:wrapText',
      'imageStyle:breakText',
      '|',
      'resizeImage',
    ],
  },
  htmlSupport: {
    allow: [{ name: /.*/, attributes: true, classes: true, styles: true }],
  },
  translations: [translations],
};
